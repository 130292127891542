"use client";
import React from "react";
import MainLayout from "@/components/layout/mainLayout";

const PrivacyPolicy = (props: any) => {
  const { data, meta, countryCode } = props;
  return (
    <MainLayout>
      <div className="privacy_policy_section">
        <div className="block white-bg">
          <div id="content" role="main">
            <div className="container">
              <div className="content-region">
                <h3 className="main_heading">Privacy Policy</h3>
                <p className="sub_header">
                  How we protect and make use of any information you give us.
                </p>
                <p className="lead">
                  Duncan Taylor Scotch Whisky Limited is a private limited
                  company based at King Street, Huntly, AB54 8HP, Scotland.
                  <br />
                </p>
                <p>
                  We respect your privacy and are determined to protect your
                  personal data. The purpose of this privacy notice is to inform
                  you as to how we look after your personal data when you visit
                  our website. We'll also tell you about your privacy rights and
                  how the data protection law protects you.
                </p>
                <p>
                  For all data matters contact the Operations Manager on{" "}
                  <a href="mailto:info@duncantaylor.com">
                    info@duncantaylor.com
                  </a>{" "}
                  or 01466 794055.
                </p>
                <p></p>
                <p>
                  <strong>1. WHO WE ARE AND IMPORTANT INFORMATION </strong>
                </p>
                <p>
                  <strong>
                    <u>What is the purpose of this privacy notice?</u>
                  </strong>
                </p>
                <p>
                  This privacy notice aims to give you information on how we
                  collect and process your personal data through your use of
                  this website, including any data you may provide through this
                  website when you submit a query via our contact form or
                  respond to campaigns which require you to enter your details
                  on an online form.
                </p>
                <p>
                  This website is not intended for children and we do not
                  knowingly collect data relating to children.
                </p>
                <p>
                  You must read this privacy notice together with any other
                  privacy notice we may provide on specific occasions when we
                  are collecting or processing personal data about you so that
                  you are fully aware of how and why we are using your data.
                  This privacy notice supplements the other notices and is not
                  intended to override them.
                </p>
                <p>
                  <strong>
                    <u>Third-party links outside of our control</u>
                  </strong>
                </p>
                <p>
                  This website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements.{" "}
                </p>
                <p>
                  When you leave our website, we encourage you to read the
                  privacy notice of every website you visit.
                </p>
                <p></p>
                <p>
                  <strong>2. THE PERSONAL DATA WE COLLECT ABOUT YOU</strong>
                </p>
                <p>
                  Personal data, or personal information, means any information
                  about an individual from which that person can be identified.
                  You can find out more about personal data from the
                  <a href="https://ico.org.uk/global/contact-us/">
                    Information Commissioners Office
                  </a>
                  .
                </p>
                <p>
                  We may collect, use, store and transfer different kinds of
                  personal data about you which we have grouped together
                  follows:
                </p>
                <ul>
                  <li>
                    <strong>Identity Data</strong> includes first name, last
                    name, username, marital status, title, date of birth and
                    gender.
                  </li>
                  <li>
                    <strong>Contact Data</strong> includes email address,
                    billing address, delivery address and telephone numbers.
                  </li>
                  <li>
                    <strong>Transaction Data</strong> includes details about
                    payments to and from you and other details of products and
                    services you have purchased from us.
                  </li>
                  <li>
                    <strong>Technical Data</strong> includes internet protocol
                    (IP) address, your login data, browser type and version,
                    time zone setting and location, browser plug-in types and
                    versions, operating system and platform and other technology
                    on the devices you use to access this website.
                  </li>
                  <li>
                    <strong>Profile Data</strong> includes your username and
                    password, purchases or orders made by you, your interests,
                    preferences, feedback and survey responses.
                  </li>
                  <li>
                    <strong>Usage Data</strong> includes information about how
                    you use our website, products and services.
                  </li>
                  <li>
                    <strong>Marketing and Communications Data</strong> includes
                    your preferences in receiving marketing from us and our
                    third parties and your communication preferences.
                  </li>
                </ul>
                <p>
                  We may also collect, use and share Aggregated Data such as
                  statistical or demographic data for any purpose. Aggregated
                  Data may be derived from your personal data but is not
                  considered personal data in law as this data does not directly
                  or indirectly reveal your identity. For example, we may
                  aggregate your Usage Data to calculate the percentage of users
                  accessing a specific website feature. However, if we combine
                  or connect Aggregated Data with your personal data so that it
                  can directly or indirectly identify you, we treat the combined
                  data as personal data which will be used in accordance with
                  this privacy notice.
                </p>
                <p>
                  We do not collect any{" "}
                  <strong>Special Categories of Personal Data</strong> about you
                  (this includes details about your race or ethnicity, religious
                  or philosophical beliefs, sex life, sexual orientation,
                  political opinions, trade union membership, information about
                  your health and genetic and biometric data). Nor do we collect
                  any information about criminal convictions and offences.
                </p>
                <p>
                  <strong>
                    <u>If you fail to provide personal data </u>
                  </strong>
                </p>
                <p>
                  Where we need to collect your personal data by law, or under
                  the terms of a contract we have with you and you fail to
                  provide that data when requested, we may not be able to
                  perform the contract we have or are trying to enter into with
                  you (for example, to provide you with goods or services). In
                  this case, we may have to cancel a product or service you have
                  with us, but we will notify you if this is the case at the
                  time.
                </p>
                <p></p>
                <p>
                  <strong>3. HOW WE COLLECT YOUR PERSONAL DATA </strong>
                </p>
                <p>
                  We use different methods to collect data from and about you
                  including through:{" "}
                </p>
                <ul>
                  <li>
                    <strong>Direct interactions</strong>. You may give us your
                    Identity, Contact and Financial Data by filling in forms or
                    by corresponding with us by post, phone, email or otherwise.
                    This includes personal data you provide when you:
                    <ul className="round_mark">
                      <li>apply for our products or services;</li>
                      <li>create an account on our website;</li>
                      <li>request marketing to be sent to you;</li>
                      <li>enter a competition, promotion or survey; or</li>
                      <li>give us some feedback.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Automated technologies or interactions</strong>. As
                    you interact with our website, we may automatically collect
                    Technical Data about your equipment, browsing actions and
                    patterns. We collect this personal data by using cookies,
                    and other similar technologies.
                  </li>
                </ul>
                <p></p>
                <p>
                  <strong>4. HOW WE USE YOUR PERSONAL DATA</strong>
                </p>
                <p>
                  We will only use your personal data when the law allows us to.
                  Most commonly, we will use your personal data in the following
                  circumstances:
                </p>
                <ul>
                  <li>
                    <strong>Performance of Contract </strong>this means
                    processing your data where it is necessary for the
                    performance of a contract to which you are a party or to
                    take steps at your request before entering into such a
                    contract.
                  </li>
                  <li>
                    <strong>Legitimate Interest</strong> this means the interest
                    of our business in conducting and managing our business to
                    enable us to give you the best service/product and the most
                    secure experience. We make sure we consider and balance any
                    potential impact on you and your rights before we process
                    your personal data for our legitimate interests. We do not
                    use your personal data for activities where our interests
                    are overridden by the impact on you.
                  </li>
                  <li>
                    <strong>
                      Comply with a legal or regulatory obligation
                    </strong>{" "}
                    this means processing your personal data where it is
                    necessary for compliance with a legal or regulatory
                    obligation that we are subject to.
                  </li>
                </ul>
                <p>
                  Generally we do not rely on consent as a legal basis for
                  processing your personal data other than in relation to
                  sending third party direct marketing communications to you via
                  email or text message. You have the right to withdraw consent
                  to marketing at any time by contacting us at
                  info@duncantaylor.com.
                </p>
                <p>
                  <strong>
                    Purposes for which we will use your personal data
                  </strong>
                </p>
                <p>
                  We have set out below, a description of all the ways we plan
                  to use your personal data, with the legal bases we rely on to
                  do so.{" "}
                </p>
                <p>
                  Note that we may process your personal data for more than one
                  lawful ground depending on the specific purpose for which we
                  are using your data. Please contact us if you need details
                  about the specific legal grounds we are relying on to process
                  your personal data where more than one ground has been set out
                  in the table below.{" "}
                </p>
                <div className="table_upper_div">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong>Purpose/Activity</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong>Type of data</strong>
                          </p>
                        </td>
                        <td>
                          <p>
                            <strong>
                              Lawful basis for processing including basis of
                              legitimate interest
                            </strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>To register you as a new customer</p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                          </p>
                        </td>
                        <td>
                          <p>Performance of a contract with you</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>To process and deliver your order, including:</p>
                          <ul>
                            <li>Manage payments, fees and charges</li>
                            <li>Collect and recover money owed to us</li>
                            <li>
                              Notifying you about changes to our terms or
                              privacy policy
                            </li>
                            <li>
                              Asking you to leave a review or take a survey
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Financial
                            <br />
                            Transaction
                            <br />
                            Marketing &amp; Comms
                          </p>
                        </td>
                        <td>
                          <p>Performance of a contract with you</p>
                          <p>
                            Necessary for our legitimate interests, to recover
                            debts due to us
                          </p>
                          <p></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>To manage our relationship with you, including:</p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Profile
                            <br />
                            Marketing &amp; Comms
                          </p>
                          <p></p>
                        </td>
                        <td>
                          <p>Performance of a contract with you</p>
                          <p>Necessary to comply with a legal obligation</p>
                          <p>
                            Necessary for our legitimate interests, to keep our
                            records updated and to study how customers use our
                            products/services
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            To enable you to take part in a competition or prize
                            draw, or complete a survey
                          </p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Profile
                            <br />
                            Usage
                            <br />
                            Marketing &amp; Comms
                          </p>
                        </td>
                        <td>
                          <p>Performance of a contract with you</p>
                          <p>
                            Necessary for our legitimate interests, to study how
                            customers use our products/services, to develop them
                            and grow our business
                          </p>
                          <p></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            To administer and protect our business and this
                            website, including troubleshooting, data analysis,
                            testing, system maintenance, support, reporting and
                            hosting of data
                          </p>
                          <p></p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Technical
                          </p>
                          <p></p>
                        </td>
                        <td>
                          <p>
                            Necessary for our legitimate interests, for running
                            our business, provision of administration and IT
                            services, network security, to prevent fraud and in
                            the context of a business reorganisation or group
                            restructuring exercise
                          </p>
                          <p>Necessary to comply with a legal obligation</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            To deliver relevant website content and
                            advertisements to you and measure or understand the
                            effectiveness of the advertising we serve to you
                          </p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Profile
                            <br />
                            Usage
                            <br />
                            Marketing and Comms
                            <br />
                            Technical
                          </p>
                        </td>
                        <td>
                          <p>
                            Necessary for our legitimate interests, to study how
                            customers use our products/services, to develop
                            them, to grow our business and to inform our
                            marketing strategy
                          </p>
                          <p></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            To use data analytics to improve our website,
                            products/services, marketing, customer relationships
                            and experiences
                          </p>
                        </td>
                        <td>
                          <p>
                            Technical
                            <br />
                            Usage
                          </p>
                        </td>
                        <td>
                          <p>
                            Necessary for our legitimate interests, to define
                            types of customers for our products and services, to
                            keep our website updated and relevant, to develop
                            our business and to inform our marketing strategy
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            To make suggestions and recommendations to you about
                            goods or services that may be of interest to you
                          </p>
                          <p></p>
                        </td>
                        <td>
                          <p>
                            Identity
                            <br />
                            Contact
                            <br />
                            Technical
                            <br />
                            Usage
                            <br />
                            Profile
                          </p>
                        </td>
                        <td>
                          <p>
                            Necessary for our legitimate interests, to develop
                            our products/services and grow our business
                          </p>
                          <p></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  <strong> </strong>
                </p>
                <p>
                  <strong>
                    <u>Marketing</u>
                  </strong>{" "}
                </p>
                <p>
                  We strive to provide you with choices regarding certain
                  personal data uses, particularly around marketing and
                  advertising.{" "}
                </p>
                <p>
                  We may use your Identity, Contact, Technical, Usage and
                  Profile Data to form a view on what we think you may want or
                  need, or what may be of interest to you. This is how we decide
                  which products, services and offers may be relevant for you.
                </p>
                <p>
                  You will receive marketing communications from us if you have
                  requested information from us or purchased goods or services
                  from us and, in each case, you have not opted out of receiving
                  that marketing.
                </p>
                <p>
                  <strong>
                    <u>Third-party marketing</u>
                  </strong>
                </p>
                <p>
                  We will get your express opt-in consent before we share your
                  personal data with any company outside Duncan Taylor for
                  marketing purposes.
                </p>
                <p>
                  <strong>
                    <u>Opting out</u>
                  </strong>
                </p>
                <p>
                  You can ask us or third parties to stop sending you marketing
                  messages at any time by emailing info@duncantaylor.com.
                </p>
                <p>
                  Where you opt out of receiving these marketing messages, this
                  will not apply to personal data provided to us as a result of
                  a purchase or other transaction.
                </p>
                <p>
                  <strong>
                    <u>Cookies</u>
                  </strong>
                </p>
                <p>
                  You can set your browser to refuse all or some browser
                  cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts
                  of this website may become inaccessible or not function
                  properly.
                </p>
                <p>
                  <strong>
                    <u>Change of purpose</u>
                  </strong>
                </p>
                <p>
                  We will only use your personal data for the purposes for which
                  we collected it, unless we reasonably consider that we need to
                  use it for another reason and that reason is compatible with
                  the original purpose. If you wish to get an explanation as to
                  how the processing for the new purpose is compatible with the
                  original purpose, please contact us at info@duncantaylor.com.
                </p>
                <p>
                  If we need to use your personal data for an unrelated purpose,
                  we will notify you and we will explain the legal basis which
                  allows us to do so.
                </p>
                <p>
                  Please note that we may process your personal data without
                  your knowledge or consent, in compliance with the above rules,
                  where this is required or permitted by law.
                </p>
                <p></p>
                <p>
                  <strong>5. WHO WE SHARE YOUR PERSONAL DATA WITH</strong>
                </p>
                <p>
                  We may have to share your personal data with the parties set
                  out below for the purposes set out in the table in paragraph 4
                  above.
                </p>
                <p>
                  We require all third parties to respect the security of your
                  personal data and to treat it in accordance with the law. We
                  do not allow our third-party service providers to use your
                  personal data for their own purposes and only permit them to
                  process your personal data for specified purposes and in
                  accordance with our instructions.
                </p>
                <p></p>
                <p>
                  <strong>6. INTERNATIONAL TRANSFERS</strong>
                </p>
                <p>
                  We share your personal data within Duncan Taylor which will
                  involve transferring your data outside the European Economic
                  Area (EEA).{" "}
                </p>
                <p>
                  Whenever we transfer your personal data out of the EEA, we
                  ensure a similar degree of protection is afforded to it by
                  implementing safeguards. Please contact us if you want further
                  information on this.
                </p>
                <p></p>
                <p>
                  <strong>7. DATA SECURITY</strong>
                </p>
                <p>
                  We have put in place appropriate security measures to prevent
                  your personal data from being accidentally lost, used or
                  accessed in an unauthorised way, altered or disclosed. In
                  addition, we limit access to your personal data to those
                  employees, agents, contractors and other third parties who
                  have a business need to know. They will only process your
                  personal data on our instructions and they are subject to a
                  duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any suspected
                  personal data breach and will notify you and any applicable
                  regulator of a breach where we are legally required to do so.
                </p>
                <p></p>
                <p>
                  <strong>8. DATA RETENTION</strong>
                </p>
                <p>
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal,
                  accounting, or reporting requirements.
                </p>
                <p>
                  To determine the appropriate retention period for personal
                  data, we consider the amount, nature, and sensitivity of the
                  personal data, the potential risk of harm from unauthorised
                  use or disclosure of your personal data, the purposes for
                  which we process your personal data and whether we can achieve
                  those purposes through other means, and the applicable legal
                  requirements.
                </p>
                <p></p>
                <p>
                  <strong>9. YOUR LEGAL RIGHTS</strong>
                </p>
                <p>
                  Unless subject to an exemption under the data protection laws,
                  you have the following rights with respect to your personal
                  data:{" "}
                </p>
                <ul>
                  <li>
                    The right to request a copy of the personal data which we
                    hold about you;
                  </li>
                  <li>
                    The right to request that we correct any personal data if it
                    is found to be inaccurate or out of date;
                  </li>
                  <li>
                    The right to request your personal data is erased where it
                    is no longer necessary to retain such data;
                  </li>
                  <li>
                    The right to withdraw your consent to the processing at any
                    time, where consent was the lawful basis for processing your
                    data;{" "}
                  </li>
                  <li>
                    The right to request that we provide you with your personal
                    data and where possible, to transmit that data directly to
                    another data controller, known as the right to data
                    portability;
                  </li>
                  <li>
                    The right, where there is a dispute in relation to the
                    accuracy or processing of your personal data, to request a
                    restriction is placed on further processing;
                  </li>
                  <li>
                    The right to object to our processing of personal data,
                    where applicable i.e. where processing is based on our
                    legitimate interests or in performance of a task in the
                    public interest/exercise of official authority; direct
                    marketing or processing for the purposes of
                    scientific/historical research and statistics.{" "}
                  </li>
                </ul>
                <p>
                  If you wish to exercise any of the rights set out above,
                  please contact the Operations Manager on
                  info@duncantaylor.com.
                </p>
                <p>
                  <strong>
                    <u>No fee required, with some exceptions</u>
                  </strong>
                </p>
                <p>
                  You will not have to pay a fee to access your personal data or
                  to exercise any of the other rights. However, we may charge a
                  reasonable admin fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we may refuse to
                  comply with your request in these circumstances.
                </p>
                <p>
                  <strong>
                    <u>What we may need from you</u>
                  </strong>
                </p>
                <p>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access your
                  personal data or to exercise any of your other rights. This is
                  a security measure to ensure that personal data is not
                  disclosed to any person who has no right to receive it. We may
                  also contact you to ask you for further information in
                  relation to your request to speed up our response.
                </p>
                <p>
                  <strong>
                    <u>Time limit to respond</u>
                  </strong>
                </p>
                <p>
                  We try to respond to all legitimate requests within one month.
                  Occasionally it may take us longer than a month if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you
                  updated.
                </p>
                <p></p>
                <p>
                  <strong>
                    10. CHANGES TO THIS NOTICE &amp; YOUR DUTY TO INFORM US OF
                    CHANGES{" "}
                  </strong>
                </p>
                <p>
                  This policy was last created on 22<sup>nd</sup> January 2019.
                  No previous versions exist.
                </p>
                <p>
                  Please keep us informed if your personal data changes during
                  your relationship with us. It is important that the personal
                  data we hold about you is accurate and current.{" "}
                </p>
                <p></p>
                <p>
                  <strong>11. QUERIES, REQUESTS OR CONCERNS</strong>
                </p>
                <p>
                  To exercise all relevant rights, queries or complaints in
                  relation to this policy or any other data protection matter
                  between you and us, please in the first instance contact our
                  Operations Manager at info@duncantaylor.com.
                </p>
                <p>
                  If this does not resolve your complaint to your satisfaction,
                  you have the right to lodge a complaint with the{" "}
                  <a href="https://ico.org.uk/global/contact-us/">
                    Information Commissioners Office
                  </a>{" "}
                  on 03031231113 or via email
                  <a href="https://ico.org.uk/global/contact-us/email/">
                    https://ico.org.uk/global/contact-us/email/
                  </a>
                  or at the Information Commissioner's Office, Wycliffe House,
                  Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy;
